<template>
  <div>
    <vs-sidebar click-not-close parent="#post-app" :hidden-background="true" class="full-vs-sidebar post-view-sidebar items-no-padding" v-model="isUpdateActive" position-right id="post-update">
      <div class="px-0 sm:pb-6 h-full task-area-loading">
        <div class="flex flex-wrap items-center justify-between md:px-8 px-6 pt-4 d-theme-dark-bg task-header bg-primary">
          <div class="flex mb-4">
            <div class="flex items-center">
              <feather-icon :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" @click="closeUpdateView" class="cursor-pointer mr-4" svg-classes="text-white w-6 h-6"></feather-icon>
              <h5 class="text-white">{{ data.nama }}</h5>
            </div>
          </div>
        </div>

        <component class="post-content-scroll-area" :settings="settings" :is="scrollbarTag" key="1">
          <div class="vx-row">
            <div class="vx-col w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4 items-center mx-auto
            border border-l-0 border-b-0 border-t-0 border-solid d-theme-border-grey-light">
              <div class="vx-row">
                <div class="vx-col w-full">
                  <vs-divider>Pemohon</vs-divider>
                  <div class="vx-row">
                    <div class="vx-col w-full mt-2">
                      <small class="date-label">Nama</small>
                      <h5>{{ data.nama }}</h5>
                    </div>
                    <div class="vx-col w-full mt-2">
                      <small class="date-label">Email</small>
                      <h5>{{ data.email }}</h5>
                    </div>
                    <div class="vx-col w-full mt-2">
                      <small class="date-label">Whatsapp</small>
                      <h5>{{ data.telepon }}</h5>
                    </div>
                    <div class="vx-col w-full mt-2">
                      <small class="date-label">Alamat</small>
                      <p>{{ data.alamat }}</p>
                    </div>
                  </div>

                </div>

                <div class="vx-col w-full">

                  <vs-divider>Keterangan</vs-divider>
                  <div class="vx-row">
                    <div class="vx-col w-full mt-2">
                      <small class="date-label">Rincian Informasi Yang Dibutuhkan</small>
                      <h4>{{ data.informasi }}</h4>
                    </div>
                    <div class="vx-col w-full mt-2">
                      <small class="date-label">Tujuan Penggunaan Informasi</small>
                      <h4>{{ data.tujuan }}</h4>
                    </div>
                    <div class="vx-col w-full mt-2">
                      <small class="date-label">Cara Memperoleh Informasi</small>
                      <h4>{{ data.peroleh_informasi }}</h4>
                    </div>
                    <div class="vx-col w-full mt-2">
                      <small class="date-label">Cara Mendapatkan Salinan Informasi</small>
                      <h4>{{ data.salinan_informasi }}</h4>
                    </div>
                  </div>

                  <vs-divider class="mt-5">File Informasi</vs-divider>
                  <div class="vx-row">
                    <iframe :src="getFile('/'+data.informasi_file)" style="width:100%;height:500px;"></iframe>
                  </div>

                </div>

              </div>
            </div>

            <div class="vx-col vx-col w-full sm:w-full md:w-full lg:w-1/4 xl:w-1/4 items-center mx-auto sm:px-5 sm:mt-5">
              <div class="vx-row">
                <div class="vx-col w-full">

                  <vs-button type="border" @click="closeUpdateView" class="mb-4 w-full">Kembali</vs-button>

                  <vs-button @click="terimaActive=true" color="success" class="mb-4 w-full">Kirim</vs-button>

                  <vs-button @click="tolakActive=true" color="danger" class="mb-4 w-full">Tolak</vs-button>

                </div>
              </div>
            </div>
          </div>
        </component>
      </div>

      <vs-popup title="Tolak" :active.sync="tolakActive">
        <div class="vx-row p-2">
          <div class="vx-col w-full mb-2 mt-5">
            <vs-textarea label="Alasan Menolak" v-model="dataTolak" class="w-full" name="Tolak"/>
          </div>
        </div>
        <vs-button :disabled="isTolakValid" @click="submitVerifikasi('tolak')" class="mb-4 w-full">Submit</vs-button>
      </vs-popup>

      <vs-popup title="Klarifikasi" :active.sync="terimaActive">

        <p class="mb-5">Yakin ingin menerima pengajuan informasi ini ?</p>

        <template v-if="Attachment">
          <template v-if="upFile">
            <iframe :src="getFile('/'+data.informasi_file)" style="width:100%;height:500px;"></iframe>
          </template>
          <template v-else>
            <iframe :src="Attachment" style="width:100%;height:500px;"></iframe>
          </template>

          <div class="modify-img flex justify-between mt-5 mb-5">
            <input type="file" class="hidden" ref="updateFileInput" @change="updateCurrFile" accept="pdf/*">
            <vs-button class="w-full" @click="$refs.updateFileInput.click();upFile = false" :disabled="disabled">Update File</vs-button>
          </div>
        </template>

        <div class="upload-img" v-if="!Attachment">
          <input type="file" class="hidden" ref="uploadFileInput" @change="updateCurrFile" accept="pdf/*">
          <vs-button @click="$refs.uploadFileInput.click(); upFile = false" class="w-full" :disabled="disabled">Upload File</vs-button>
        </div>

        <vs-button :disabled="isTerimaValid" color="success" @click="submitVerifikasi('kirim')" class="mt-5 w-full">Kirim</vs-button>
      </vs-popup>

      <vs-popup title="Alasan Tolak" :active.sync="tolaksActive">
        <p>{{ data.tolak }}</p>
      </vs-popup>

    </vs-sidebar>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import VuePerfectScrollbar  from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'
export default{
  props: {
    isUpdateActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      // Set
        settings: {
          maxScrollbarLength: 60,
          wheelSpeed: 0.50
        },
        disabled: false,

      statusVerifikasi: 'false',
      dataTaskID: 0,

      pdfPreview : false,
      previewPDF : null,

      terimaActive: false,
      tolaksActive: false,

      tolakActive: false,
      dataTolak: '',

      Attachment : null,
      upFile: true,

      dataFile: null
    }
  },
  components: {
    Datepicker,
    VuePerfectScrollbar
  },
  directives: {
  },
  watch: {
    isUpdateActive (value) {
      if (value) {
        if (Object.entries(this.data).length === 0) {
            this.disabled = false
            this.initValues()
            this.$validator.reset()
        }
      } else {return}
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    ...mapGetters({
      user : 'auth/user',
      role : 'auth/otoritas'
    }),
    isTolakValid () {
      return !this.errors.any() && this.dataTolak === ''
    },
    isTerimaValid () {
      return this.upFile != false
    },
    filter () {
      return this.$route.params.filter
    },
    taskList () { return this.$store.state.verifikasi.keterangan_berkas }
  },
  created () {
  },
  methods: {
    submitVerifikasi (status) {
      this.$vs.loading()
      let formData = new FormData()
      formData.set('id', this.data.ID)
      formData.set('biaya', this.dataBiaya)
      formData.set('status', status)

      let relation_update =  {
        'permohonan': status
      }
      formData.set('relation_update', JSON.stringify(relation_update))

      formData.set('tolak', this.dataTolak)

      formData.set('attachment_upload', this.upFile)
      if (this.upFile === false) {
        formData.append('attachment_file', this.dataFile)
      }

      this.$store.dispatch('post/statusUpdate', formData)
      .then((response) => {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Berhasil',
          color  : 'success',
          text  : response.message,
        })
        this.initValues()
        this.closeUpdateView()
        this.initValues()
      })
      .catch(error => {
        this.$vs.loading.close()
        let {data} = error.response
        this.$vs.notify({
          title: 'Error',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    initValues() {

      this.statusVerifikasi= 'false'
      this.dataTaskID= 0

      this.pdfPreview = false
      this.previewPDF = null

      this.terimaActive= false
      this.tolaksActive= false

      this.tolakActive= false
      this.dataTolak= ''

      this.Attachment = null
      this.upFile = true

      this.dataFile = null
    },
    //
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year, month, day].join('-');
    },
    closeUpdateView() {
      this.initValues()
      this.$emit('closeUpdate')
      this.initValues()
    },
    updateCurrFile (input) {
      this.dataFile = input.target.files[0]
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.Attachment = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.task-view-sidebar {
  ::v-deep .vs-sidebar {
    .vs-table--search {
      margin-left: 0;
      max-width: 100%;
      width: 100%;
    }
    .vs-con-table .vs-con-tbody {
      background: none;
      border: none;
      height: 200px;
    }
    .vs-table--search-input {
      width: 100%;
    }
    .vs-table--tbody-table {
      min-width: 0;
    }
  }
}
</style>
