
<template>
    <div class="post__filters-container">

        <div class="px-6 pt-6">
            <router-link tag="span" class="flex cursor-pointer" :class="{'text-primary': postFilter == 'all'}" :to="`${baseUrl}/filter/all`">
                <feather-icon icon="LayersIcon" :svgClasses="[{'text-primary stroke-current': postFilter == 'all'}, 'h-6 w-6']"></feather-icon>
                <span class="ml-3">All</span>
            </router-link>
        </div>

        <vs-divider></vs-divider>

        <div class="px-6 py-4">
            <div class="flex items-center flex-wrap justify-between">
                <div class="flex items-center">
                  <h5>Status</h5>
                </div>
            </div>
            <template v-for="(kategori, ind) in status">
                <router-link tag="span" class="flex mt-6 cursor-pointer" :to="`${baseUrl}/permohonan/${kategori.slug}`" :key="ind">
                    <span class="" :class="{'text-primary': postFilter == kategori.slug}">{{ kategori.name }}</span>
                </router-link>
            </template>
        </div>

        <vs-divider></vs-divider>

        <div class="px-6 py-4">
          <router-link tag="span" class="flex cursor-pointer" :class="{'text-primary': postFilter == 'trash'}" :to="`${baseUrl}/filter/trash`">
              <feather-icon icon="TrashIcon" :svgClasses="[{'text-primary stroke-current': postFilter == 'trash'}, 'h-6 w-6']"></feather-icon>
              <span class="text-lg ml-3">Trash</span>
          </router-link>
        </div>
    </div>
</template>

<script>

export default{
  components: {
  },
  props: {
    postFilter: {
      type: String,
      required: true
    },
    dateMulai: {
      type: String,
    },
    dateSampai: {
      type: String,
    },
    Search: {
      type: String,
    }
  },
  data () {
    return {
      enableCross: false,
      tooltipMerge: false,
      formatter: value => `￥${value}`,

      activePromptTags: false,
      dataTag: null,
      dataColor: 'pilih',
      dataColorx: ['primary', 'success', 'danger', 'warning', 'dark']
    }
  },
  computed: {
    validateTag () {
      return !this.errors.any() && this.dataTag && this.dataColor != 'pilih'
    },
    baseUrl () {
      const path = this.$route.path
      const paths = path.slice(0, path.lastIndexOf('/'))
      return paths.slice(0, paths.lastIndexOf('/'))
    },
    status () {
      return this.$store.state.post.postKategoris
    }
  },
  methods: {
    clearTag () {
      this.dataTag = null
      this.dataColor = 'pilih'
    },
  },
  created () {
  }
}
</script>
